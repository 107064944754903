import { IdType } from "@common/types/apiTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { usePlans } from "@pricing-data/hooks/queries/usePlans";
import { useUtilities } from "@pricing-data/hooks/queries/useUtilities";
import { PlanOrderOptions } from "@pricing-utils/types/planTypes";
import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

export interface OfferSearchFormValues {
  active: boolean;
  plan: IdType;
  utility: IdType;
}

interface OfferSearchFormProps {
  onSubmitSuccess: (values: OfferSearchFormValues) => void;
}

const StyledSelect = styled.select`
  max-height: min-content;
  width: 200px;
`;

const StyledBoLabel = styled(BoLabel)`
  align-items: center;
  display: inline-flex;
  gap: ${rhOpsSpacingPx(1)};
  height: min-content;
  &:last-of-type {
    margin-left: auto;
  }
`;

const StyledForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
`;

export const OfferSearchForm = ({ onSubmitSuccess }: OfferSearchFormProps) => {
  const { handleSubmit, watch, register, resetField } =
    useForm<OfferSearchFormValues>({
      defaultValues: {
        active: true,
      },
      mode: "onChange",
    });
  const utility = watch("utility", "");
  const plan = watch("plan", "");

  const utilitiesQuery = useUtilities();
  const plansQuery = usePlans({
    queryOptions: {
      enabled: !!utility,
    },
    searchOptions: {
      archived: false,
      ordering: PlanOrderOptions["Title A->Z"],
      utilityId: utility,
    },
  });

  // map utils to options
  const utilitiesOptions =
    utilitiesQuery.data?.map((util) => ({
      label: util.name,
      value: util.id,
    })) ?? [];

  const plansOptions =
    plansQuery.data?.map((_plan) => ({
      label: _plan.title,
      value: _plan.id,
    })) ?? [];

  const utilitiesDisabled = Boolean(utilitiesOptions.length === 0);

  const plansDisabled = Boolean(!utility || plansOptions.length === 0);

  const utilitySelectProps = register("utility", {
    disabled: utilitiesDisabled,
    onChange: () => resetField("plan"),
  });

  const planSelectProps = register("plan", {
    disabled: plansDisabled,
  });

  const onSubmit = handleSubmit((values) => onSubmitSuccess(values));

  return (
    <StyledForm onSubmit={onSubmit} aria-label="Offer Search Form">
      <StyledBoLabel htmlFor="utility-select">
        Utility:
        <StyledSelect id="utility-select" required {...utilitySelectProps}>
          <option aria-label="Null" />
          {utilitiesOptions.map((util) => {
            return (
              <option value={util.value} key={util.value}>
                {util.label}
              </option>
            );
          })}
        </StyledSelect>
      </StyledBoLabel>
      {utilitiesQuery.isError ? <span>Error fetching utilities</span> : null}
      {utilitiesQuery.data?.length === 0 && utilitiesQuery.isSuccess ? (
        <span>No Utilities found</span>
      ) : null}

      <StyledBoLabel htmlFor="plan-select">
        Plan:
        <StyledSelect id="plan-select" required {...planSelectProps}>
          <option aria-label="Null" />
          {plansOptions.map((_plan) => {
            return (
              <option value={_plan.value} key={_plan.value}>
                {_plan.label}
              </option>
            );
          })}
        </StyledSelect>
      </StyledBoLabel>
      {plansQuery.isError ? <span>Error fetching plans</span> : null}

      <StyledBoLabel htmlFor="active-only">
        Active?
        <input
          id="active-only"
          type="checkbox"
          {...register("active", {
            disabled: plansQuery.isError || utilitiesQuery.isError,
          })}
        />
      </StyledBoLabel>

      <BoButton disabled={!plan} type="submit">
        Search
      </BoButton>
    </StyledForm>
  );
};
