import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlanQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import {
  PlanPricingTypes,
  PlanSearchOptions,
} from "@pricing-utils/types/planTypes";
import { useQuery } from "@tanstack/react-query";

interface UsePlanProps {
  queryOptions?: {
    enabled?: boolean;
  };
  searchOptions?: PlanSearchOptions;
}

export const usePlans = (props: UsePlanProps = {}) => {
  const {
    queryOptions: { enabled = true } = {},
    searchOptions: {
      utilityId,
      archived,
      ordering,
      pricingType = PlanPricingTypes.FIXED_RATE,
    } = {},
  } = props;

  return useQuery({
    enabled,
    queryFn: () =>
      pricingApi.plans.index({ archived, ordering, pricingType, utilityId }),
    queryKey: PlanQueryKeys.list({
      archived,
      ordering,
      pricingType,
      utilityId,
    }),
    staleTime: 10000,
  });
};
