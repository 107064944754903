import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { pricingUrls } from "@pricing-data/api/pricingUrls";
import {
  CampaignOrderOptions,
  CampaignSearchFilters,
  CampaignType,
} from "@pricing-utils/types/campaignTypes";
import {
  OfferBulkUpdateRequestType,
  OfferOrderOptions,
  OfferPromoType,
  OfferSearchOptions,
  OfferType,
} from "@pricing-utils/types/offerTypes";
import {
  PlanRequestType,
  PlanType,
  PlanUploadResponseType,
} from "@pricing-utils/types/planTypes";
import {
  OfferLabelListResponseType,
  OfferPromoCreateResponseType,
  OfferPromoListResponseType,
} from "@pricing-utils/types/pricingApiResponseTypes";
import {
  OrderFilterParams,
  PremiseRenewalsSearchType,
  PremiseSearchFilters,
  RenewalSegmentationOfferCreateRequestType,
  RenewalSegmentationOfferUpdateRequestType,
  RenewalSegmentationOffersType,
  RenewalSegmentationOrderOptions,
  RenewalSegmentationSearchOptions,
  SetRenewalCampaignRequestType,
  SetRenewalOffersRequestType,
} from "@pricing-utils/types/renewalsTypes";

const PLANS_UPLOAD_FILE_NAME = "Plan_menu.csv";
const PREMISE_ID_UPLOAD_FILE_NAME = "premise_ids.csv";

export const pricingApi = {
  campaigns: {
    create: (newCampaign: Partial<CampaignType>) =>
      ajax.post<CampaignType>(pricingUrls.campaigns.create(), newCampaign),
    list: (searchOptions: CampaignSearchFilters) => {
      const filteredOptions = Object.fromEntries(
        Object.entries(searchOptions).filter(([key, value]) => value !== "")
      );

      return ajax.get<PaginationEndpointResponseType<CampaignType>>(
        pricingUrls.campaigns.index(),
        {
          params: {
            ...filteredOptions,
            // Add a second sort to prevent mis-ordering of values on different requests
            ordering: searchOptions.ordering
              ? [
                  searchOptions.ordering,
                  CampaignOrderOptions["Utility: A->Z"],
                ].join(",")
              : undefined,
          },
        }
      );
    },
    retrieve: (id: IdType) => {
      return ajax.get<CampaignType>(pricingUrls.campaigns.retrieve(id));
    },
  },
  offerLabels: {
    list: () => {
      return ajax.get<OfferLabelListResponseType>(
        pricingUrls.offerLabels.list(),
        {
          params: {
            limit: 1000,
          },
        }
      );
    },
  },
  offerPromos: {
    create: (newOfferPromo: Partial<OfferPromoType>) => {
      return ajax.post<OfferPromoCreateResponseType>(
        pricingUrls.offerPromos.create(),
        newOfferPromo
      );
    },
    list: () => {
      return ajax.get<OfferPromoListResponseType>(
        pricingUrls.offerPromos.list(),
        {
          params: {
            limit: 1000,
          },
        }
      );
    },
  },
  offers: {
    bulkUpdate: (requestData: OfferBulkUpdateRequestType) =>
      ajax.patch<null>(pricingUrls.offers.bulkUpdate(), requestData),
    list: (searchOptions: OfferSearchOptions) => {
      return ajax.get<PaginationEndpointResponseType<OfferType>>(
        pricingUrls.offers.list(),
        {
          params: {
            ...searchOptions,
            // Add a second sort to prevent mis-ordering of values on different requests
            ordering: searchOptions.ordering
              ? [
                  searchOptions.ordering,
                  OfferOrderOptions["Id: Low to High"],
                ].join(",")
              : undefined,
          },
        }
      );
    },
    retrieve: (offerId: IdType) =>
      ajax.get<OfferType>(pricingUrls.offers.retrieve(offerId)),
  },
  plans: {
    archive: (id: IdType): Promise<void> => {
      return ajax.post<void>(pricingUrls.plans.archive(id), {});
    },
    download: (): Promise<Blob> => {
      return ajax.getFile(pricingUrls.plans.download());
    },
    index: (plansRequestType: PlanRequestType = {}): Promise<PlanType[]> => {
      return ajax.get(pricingUrls.plans.index(), {
        params: plansRequestType,
      });
    },
    retrieve: (id: IdType): Promise<PlanType> =>
      ajax.get(pricingUrls.plans.retrieve(id)),
    upload: (csvFile: File): Promise<PlanUploadResponseType> => {
      const formData = new FormData();

      formData.append("plans_file", csvFile, PLANS_UPLOAD_FILE_NAME);
      return ajax.postFile(pricingUrls.plans.upload(), formData);
    },
  },
  renewals: {
    premises: {
      csvSearch: (
        filters: PremiseSearchFilters
      ): Promise<PaginationEndpointResponseType<PremiseRenewalsSearchType>> => {
        const formData = new FormData();

        formData.append("duns", filters.duns);

        if (filters.premiseIdsFile) {
          formData.append(
            "premise_ids",
            filters.premiseIdsFile,
            PREMISE_ID_UPLOAD_FILE_NAME
          );
        }

        return ajax.postFile(
          pricingUrls.renewals.premises.csvSearch(),
          formData
        );
      },
      orderSearch: (
        params: OrderFilterParams
      ): Promise<PaginationEndpointResponseType<PremiseRenewalsSearchType>> => {
        return ajax.get(pricingUrls.renewals.premises.ordersSearch(), {
          params,
        });
      },
    },
    segmentationOffers: {
      create: (data: RenewalSegmentationOfferCreateRequestType) =>
        ajax.post(pricingUrls.renewals.segmentationOffers.create(), data),
      list: (
        searchOptions: RenewalSegmentationSearchOptions
      ): Promise<
        PaginationEndpointResponseType<RenewalSegmentationOffersType>
      > =>
        ajax.get(pricingUrls.renewals.segmentationOffers.list(), {
          params: {
            ...searchOptions,
            // Add a second sort to prevent mis-ordering of values on different requests
            ordering: searchOptions.ordering
              ? [
                  searchOptions.ordering,
                  RenewalSegmentationOrderOptions["Id: Low to High"],
                ].join(",")
              : undefined,
          },
        }),
      update: (data: RenewalSegmentationOfferUpdateRequestType) =>
        ajax.put(pricingUrls.renewals.segmentationOffers.update(data.id), data),
    },
    setRenewalCampaign: (
      renewalCampaignToSet: SetRenewalCampaignRequestType
    ): Promise<void> =>
      ajax.post(pricingUrls.renewals.setRenewalCampaign(), {
        ...renewalCampaignToSet,
      }),
    setRenewalOffers: (
      renewalOffers: SetRenewalOffersRequestType
    ): Promise<void> =>
      ajax.post(pricingUrls.renewals.setRenewalOffers(), { ...renewalOffers }),
  },
};
