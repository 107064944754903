import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import {
  BoTable,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { trueWhite } from "@ops-design-system/styles/palette.colors";
import { OfferType } from "@pricing-utils/types/offerTypes";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import styled from "styled-components";

const OfferTableContainer = styled.div`
  max-height: 150px;
  overflow: auto;
`;

const StyledOfferTable = styled(BoReactTable<OfferType>)`
  & ${BoTable} ${BoTableHead} ${BoTableRow} ${BoTableHeadCell} {
    position: sticky;
    top: 0;
  }
  & ${BoTable} ${BoTableHeadCell} {
    background-color: ${trueWhite};
  }

  & ${BoTableCell} {
    text-align: center;
    white-space: nowrap;
    width: 1%;
  }
`;

const columnHelper = createColumnHelper<OfferType>();

const columns = [
  columnHelper.accessor("id", {
    header: "Id",
  }),
  columnHelper.accessor("brandSlug", {
    header: "Brand",
  }),
  columnHelper.accessor("campaignSlug", {
    header: "Campaign",
  }),
  columnHelper.accessor("priceMethod", {
    header: "Price Method",
  }),
  columnHelper.accessor("price2000Kwh", {
    header: "Price",
  }),
  columnHelper.accessor("solarBuybackKwhRate", {
    header: "Solar Buyback Rate",
  }),
  columnHelper.accessor("startDate", {
    header: "Start Date",
  }),
  columnHelper.accessor("endDate", {
    header: "End Date",
  }),
  columnHelper.accessor("earliestServiceStartDate", {
    header: "Earliest Service Start Date",
  }),
];

export const SelectedOffersTable = ({ offers }: { offers: OfferType[] }) => {
  if (offers.length === 0) {
    return <BoCard>No Offers selected, return to table</BoCard>;
  }

  return (
    <>
      <Body1 $fontWeight="Bold">Selected Offers</Body1>
      <OfferTableContainer>
        <StyledOfferTable
          columns={columns}
          data={offers}
          tableCaption="Selected Offers Table"
        />
      </OfferTableContainer>
    </>
  );
};
