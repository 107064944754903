import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlanQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePlanArchiveMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: pricingApi.plans.archive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PlanQueryKeys.BASE });
    },
  });
};
