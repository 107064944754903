import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { yesNo } from "@ops-design-system/utils/booleanHelpers";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { usePlanArchiveMutation } from "@pricing-data/hooks/mutations/usePlanArchive.mutation";
import { usePlans } from "@pricing-data/hooks/queries/usePlans";
import { PlanType } from "@pricing-utils/types/planTypes";
import { PlansUploadDialog } from "@pricing/components/PlansUploadDialog/PlansUploadDialog";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: flex-start;
  margin-bottom: ${rhOpsSpacingPx(4)};
`;

const columnHelper = createColumnHelper<PlanType>();

export const PlansPage = () => {
  const flash = useRhFlash();
  const plansQuery = usePlans();

  const planArchiveMutation = usePlanArchiveMutation();

  const columns = [
    columnHelper.accessor("id", {
      header: "Id",
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor("brand", {
      header: "Brand",
      meta: {
        fitContent: true,
      },
    }),
    columnHelper.accessor("title", {
      header: "Title",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("descriptionEn", {
      header: "Description",
    }),
    columnHelper.accessor("loadProfile", {
      header: "Load Profile",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("product", {
      header: "Product",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("utility.name", {
      header: "Utility",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("recProduct", {
      header: "Rec Product",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("termedCogsAmountMwh", {
      header: "$/MWh Termed COGS",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("annualVolumeMwh", {
      header: "Annual Volume (MWh)",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("tdspCustomerChargeAmount", {
      header: "TDSP Customer Charge ($)",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("tdspPerUnitMwhAmount", {
      header: "TDSP Per Unit ($/MWh)",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("baseChargeAmount", {
      header: "Base Charge ($)",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("etfAmount", {
      header: "ETF Amount ($)",
      meta: {
        wrapContent: true,
      },
    }),
    columnHelper.accessor("isGreen", {
      cell: ({ cell }) => {
        return yesNo(cell.getValue());
      },
      header: "Green?",
    }),
    columnHelper.accessor("solarEligible", {
      cell: ({ cell }) => {
        return yesNo(cell.getValue());
      },
      header: "Solar Eligible?",
    }),
    columnHelper.accessor("solarGenerationCapped", {
      cell: ({ cell }) => {
        return yesNo(cell.getValue());
      },
      header: "Solar Generation Capped?",
    }),
    columnHelper.accessor("solarGenerationToConsumptionRatio", {
      header: "Generation to Consumption Ratio (%)",
    }),
    columnHelper.accessor("archived", {
      cell: ({ cell, row }) => {
        if (cell.getValue()) {
          return <RhTypography color="error">Archived</RhTypography>;
        }

        return (
          <BoButton
            onClick={() => {
              archivePlan(row.original.id);
            }}
            disabled={planArchiveMutation.isPending}
            color="default"
            size="small"
            fullWidth={false}
            variant="secondary"
          >
            Archive Plan
          </BoButton>
        );
      },
      header: "Status",
    }),
  ];

  const csvDownload = async () => {
    const blob = await pricingApi.plans.download();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;
    const fileName = `allRhythmPlans${dayjs().format(ISO8601_DATE_FORMAT)}.csv`;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  if (plansQuery.isPending) {
    return <BoCircularProgress />;
  }
  if (plansQuery.isError) {
    return <Body1>Error fetching plans</Body1>;
  }

  const archivePlan = (planId: IdType) => {
    planArchiveMutation.mutate(planId, {
      onError: () => {
        flash.error("Error archiving plans. Please try again.");
      },
      onSuccess: () => {
        flash.success("Plan archived");
      },
    });
  };

  return (
    <Container>
      <HeaderContainer>
        <RhTypography variant="h1">Plans</RhTypography>
        <BoButton
          onClick={() => csvDownload()}
          color="primary"
          size="small"
          fullWidth={false}
        >
          Download Plans
        </BoButton>

        <PlansUploadDialog />
      </HeaderContainer>

      <BoReactTable<PlanType>
        tableCaption="Plans"
        columns={columns}
        data={plansQuery.data ?? []}
      />
    </Container>
  );
};
