import { IdType } from "@common/types/apiTypes";
import { PlanPricingTypes } from "@pricing-utils/types/planTypes";

export enum PriceMethodTypes {
  Fixed = "FIXED",
  Margin = "MARGIN",
}

export interface OfferPromoType {
  code: string;
  expirationDate: string | null;
  futureValue: number | null;
  id: IdType;
  presentationValue: number | null;
  value: number;
}

export interface OfferLabelType {
  label: string;
}

export interface OfferBulkUpdateRequestType {
  earliestServiceStartDate?: string;
  endDate?: string;
  grossMargin?: string;
  offers: IdType[];
  planId: IdType;
  priceMethod?: PriceMethodTypes;
  pricePerMwh?: string;
  solarBuybackKwhRate?: string;
  startDate?: string;
}

export enum OfferOrderOptions {
  "Campaign A->Z" = "campaign",
  "Campaign Z->A" = "-campaign",
  "Id: High to Low" = "-id",
  "Id: Low to High" = "id",
  "Price: High to Low" = "-price",
  "Price: Low to High" = "price",
  "Title: A->Z" = "title",
  "Title: Z->A" = "-title",
}

export interface OfferSearchOptions {
  active?: boolean;
  campaignId?: IdType;
  limit?: number;
  offset?: number;
  ordering?: OfferOrderOptions;
  planId?: IdType;
}

export interface OfferType {
  autopayPaperlessMonthlyDiscount: string | null;
  baseChargeAmount: string;
  brandSlug: string;
  campaignId: IdType;
  campaignSlug: string;
  descriptionEn: string;
  descriptionEs: string;
  earliestServiceStartDate: string | null;
  endDate: string | null;
  gracePeriodDays: number;
  grossMargin: string | null;
  id: IdType;
  isActive: boolean;
  longDescriptionEn: string;
  longDescriptionEs: string;
  maxServiceStartDateDays: number | null;
  offerLabels: string[] | null;
  planId: IdType;
  price1000Kwh: string;
  price2000Kwh: string;
  price500Kwh: string;
  priceMethod: PriceMethodTypes;
  pricePerMwh: string | null;
  pricingType: PlanPricingTypes;
  priority: number;
  promo?: OfferPromoType;
  solarBuybackKwhRate: string | null;
  solarCreditKwhRate: string | null;
  solarEligible: boolean;
  startDate: string;
  tags: {
    calloutColor?: string | null;
    calloutEn?: string | null;
    calloutEs?: string | null;
  } | null;
  tdspKwhRate: string;
  tdspMonthlyCharge: string;
  termMonths: number;
  thermostatManagementMonthlyDiscount: string | null;
  title: string;
  utilityId: IdType;
  utilityName: string;
}
