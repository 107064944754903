import { FeatureFlagClientProvider } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider";
import { useOktaAuth } from "@okta/okta-react";
import React, { PropsWithChildren } from "react";

interface FeatureFlagClientPricingProviderProps {}

export const FeatureFlagClientPricingProvider = ({
  children,
}: PropsWithChildren<FeatureFlagClientPricingProviderProps>) => {
  const { authState } = useOktaAuth();
  const email = authState?.accessToken?.claims?.email;

  if (!email) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <FeatureFlagClientProvider featureFlagUserId={email}>
      {children}
    </FeatureFlagClientProvider>
  );
};
